var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticStyle:{"color":"white"},attrs:{"elevation":"0","color":"black"}},[_c('v-container',{staticClass:"d-flex flex-column",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4","justify":"center"}},[_c('v-toolbar-title',{staticClass:"d-flex"},[_c('v-img',{attrs:{"src":require("../../../public/apple-touch-icon-144x144-removebg-preview.png")}}),_c('span',{staticClass:"ma-auto",attrs:{"id":"heading"}},[_vm._v("East Gold Tours")])],1)],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"4"}},[_c('span',{staticClass:"text-h5 mb-4"},[_vm._v("Company")]),_c('span',{staticClass:"grey--text text-lighten-3"},[_vm._v("About")]),_c('span',{staticClass:"grey--text text-lighten-3"},[_vm._v("Contact")])]),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"4"}},[_c('span',{staticClass:"text-h5 mb-4"},[_vm._v("Product")]),_c('span',{staticClass:"grey--text text-lighten-3"},[_vm._v("Pricing")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"overline grey--text text-lighten-3"},[_vm._v(" ©2023 Designed by Senova Enterprise ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('a',{attrs:{"href":"https://www.facebook.com/EastGoldTours/","target":"_blank"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"size":"30","color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-facebook")])]}}])},[_c('span',[_vm._v("facebook")])])],1),_c('a',{attrs:{"href":"https://wa.me/+254720687790","target":"_blank"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"size":"30","color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-whatsapp")])]}}])},[_c('span',[_vm._v("whatsapp")])])],1),_c('a',{attrs:{"href":"tel:+254720687790"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"size":"30","color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-phone")])]}}])},[_c('span',[_vm._v("phone")])])],1),_c('a',{attrs:{"href":"mailto:your.email@example.com","target":"_blank"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"30","color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-at")])]}}])},[_c('span',[_vm._v("email")])]),_vm._v(" eastgoldtours@gmail.com ")],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"size":"30","color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-map-marker-radius")])]}}])},[_c('span',[_vm._v("location")])]),_c('a',{attrs:{"href":"https://www.instagram.com/EastGoldTours/","target":"_blank"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"size":"30","color":"white"}},'v-icon',attrs,false),on),[_vm._v("mdi-instagram")])]}}])},[_c('span',[_vm._v("instagram")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }