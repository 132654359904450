<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      right
      fixed
      stateless
      height="100%"
      width="500"
      temporary
      class="elevation-3 v-navigation-drawer--is-mobile"
      v-click-outside="onClickOutSide"
    >
      <v-card flat>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="onClickOutSide" style="cursor: pointer">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text></v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
export default {
  name: "CarDrawer",
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {
    Event.$on("openDrawer", () => {
      setTimeout(() => {
        this.drawer = true;
      }, 200);
    });
  },
  methods: {
    onClickOutSide() {
      if (!this.drawer) return;
      setTimeout(() => {
        if (this.drawer) this.drawer = false;
      }, 200);
    },
  },
};
</script>
