import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#215127",
        secondary: "#B88C2D",
        accent: colors.orange.lighten3,
        aside: colors.lime.darken4,
        info: colors.cyan.darken1,
        error: colors.red.accent3,
      },
      dark: {
        background: colors.grey.darken3,
      },
    },
  },
});
