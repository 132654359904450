<template>
  <div style="overflow-y: scroll; max-height: 100vh">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  }),
  // computed: {
  //   isIdle() {
  //     return this.$store.state.idleVue.isIdle;
  //   },
  // },
};
</script>
