<template>
  <v-app>
    <v-main class="mx-lg-16 pt-0">
      <v-row class="justify-space-around mx-md-16 my-3 my-sm-12 text-center">
        <v-col cols="12" md="6">
          <h1 class="text-md-h2 text-capitalize mb-4">east gold tours</h1>
          <h4 class="subtitle-1 mb-10">
            Jobs fill your pocket but adventures fill your soul
          </h4>
          <div class="d-flex justify-center">
            <v-btn
              class="primary white--text mr-15"
              x-large
              elevation="0"
              :to="{ name: 'ApplicationDashboard' }"
            >
              book a tour
            </v-btn>
            <v-btn class="secondary white--text" x-large>learn more</v-btn>
          </div>
          <div class="caption ma-md-5 my-sm-5">
            <v-icon class="error--text pa-3">mdi-pin</v-icon>
            From Kenya to the world
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <v-img
            src="../../../../public/tours.jpg"
            class="ml-md-16 ml-sm-10"
            :class="$vuetify.breakpoint.mdAndUp ? 'imageSize' : 'imageSize-sm'"
          />
          <v-card
            class="text-uppercase mt-n16 ml-md-n12 pa-4 text-lg-h2 text-md-h6 primary white--text"
            elevation="0"
            :class="
              $vuetify.breakpoint.mdAndUp ? 'max-width-md' : 'max-width-sm'
            "
          >
            discovery, growth & loving fulfillment
          </v-card>
        </v-col>
      </v-row>

      <v-divider class="my-5" />

      <v-container fluid>
        <div class="mb-5 text-center">
          <h1 class="font-weight-bold text-h4 capitalize">
            <span class="primary--text text-uppercase">
              available services
            </span>
          </h1>
        </div>
        <v-row>
          <v-col cols="10" offset="1">
            <v-row class="justify-center">
              <v-col
                md="4"
                cols="12"
                class="d-flex"
                v-for="(item, i) in services"
                :key="i"
              >
                <v-hover
                  v-slot="{ hover }"
                  open-delay="100"
                  style="width: 100%"
                >
                  <v-card
                    outlined
                    rounded-pill
                    :class="{ 'on-hover': hover }"
                    class="py-10"
                  >
                    <v-card-text class="text-center">
                      <div style="min-height: 150px">
                        <v-icon color="accent" x-large> {{ item.icon }}</v-icon>

                        <h3
                          class="text-uppercase font-weight-medium primary--text my-4"
                        >
                          {{ item.name }}
                        </h3>
                        <p class="caption">{{ item.description }}</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-divider class="my-5" />

      <div class="text-center d-flex flex-column fixed-buttons">
        <v-btn fab class="mb-3" dark small>
          <a href="https://www.facebook.com/EastGoldTours/" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="white" size="20" v-on="on" v-bind="attrs"
                  >mdi-facebook</v-icon
                >
              </template>
              <span>facebook</span>
            </v-tooltip>
          </a>
        </v-btn>

        <v-btn fab dark class="mb-3" small>
          <a href="https://wa.me/+254720687790" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="20" color="white" v-on="on" v-bind="attrs"
                  >mdi-whatsapp</v-icon
                >
              </template>
              <span>whatsapp</span>
            </v-tooltip>
          </a>
        </v-btn>

        <v-btn fab dark class="mb-3" small>
          <a href="tel:+254720687790">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="20" color="white" v-on="on" v-bind="attrs"
                  >mdi-phone</v-icon
                >
              </template>
              <span>phone</span>
            </v-tooltip>
          </a>
        </v-btn>

        <v-btn fab dark class="mb-3" small>
          <a href="mailto:your.email@example.com" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="20" color="white" v-on="on" v-bind="attrs"
                  >mdi-at</v-icon
                >
              </template>
              <span>email</span>
            </v-tooltip>
          </a>
        </v-btn>

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="30"
              color="white"
              class="ml-3"
              v-on="on"
              v-bind="attrs"
              >mdi-map-marker-radius</v-icon
            >
          </template>
          <span>location</span>
        </v-tooltip> -->
        <v-btn fab dark class="mb-3" small>
          <a href="https://www.instagram.com/EastGoldTours/" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="20" color="white" v-on="on" v-bind="attrs"
                  >mdi-instagram</v-icon
                >
              </template>
              <span>instagram</span>
            </v-tooltip>
          </a>
        </v-btn>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "landingPage",
  data() {
    return {
      drawer: false,
      group: null,
      leftDrawer: false,
      mini: false,
    };
  },
  computed: {
    services() {
      return [
        {
          name: "Accommodation Services (Hotel Bookings):",
          icon: "mdi-airport",
          description:
            "Reserving hotel rooms for individuals and groups.Negotiating rates and securing favorable terms with hotels.Providing information about various hotel options, amenities, and locations.",
        },
        {
          name: "Travel and Tour Services (Excursions, Cruises, FITs, Tour Operating):",
          icon: "mdi-account-arrow-right",
          description:
            "Organizing excursions and sightseeing tours.Arranging cruise packages.Creating customized itineraries for Free Independent Travelers (FITs).Operating guided tours and travel packages.",
        },
        {
          name: "Event Management Services (Conferences, Meetings, Incentives, Gala Dinners, Events):",
          icon: "mdi-account-tie",
          description:
            "Organizing conferences and meetings.Planning and executing incentive travel programs.Managing gala dinners and themed events.Coordinating product launches and corporate functions.",
        },
      ];
    },
    links() {
      return this.$store.getters["Dashboard/links"].map((link) => {
        link.sublinks = link.sublinks || [];
        return link;
      });
    },
  },
};
</script>

<style scoped>
.imageSize {
  width: 80%;
  height: 250px;
}
.imageSizesm {
}
.max-width-sm {
  width: 80%; /* Adjust the maximum width for small screens */
}

.max-width-md {
  width: 400px;
  font-size: 30px !important;
}
.fixed-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000; /* Adjust the z-index as needed to ensure it's above other elements */
  padding: 10px; /* Optional: Add padding for better visibility */
}
</style>
