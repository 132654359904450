<template>
  <v-footer elevation="0" color="black" style="color: white">
    <v-container class="d-flex flex-column" fluid>
      <v-row align="center" no-gutters>
        <v-col cols="4" class="d-flex" justify="center">
          <v-toolbar-title class="d-flex">
            <v-img
              src="../../../public/apple-touch-icon-144x144-removebg-preview.png"
            ></v-img>
            <span id="heading" class="ma-auto">East Gold Tours</span>
          </v-toolbar-title>
        </v-col>
        <v-col cols="4" class="d-flex flex-column">
          <span class="text-h5 mb-4">Company</span>
          <span class="grey--text text-lighten-3">About</span>
          <span class="grey--text text-lighten-3">Contact</span>
        </v-col>
        <v-col cols="4" class="d-flex flex-column">
          <span class="text-h5 mb-4">Product</span>
          <span class="grey--text text-lighten-3">Pricing</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <span class="overline grey--text text-lighten-3">
            &copy;2023 Designed by Senova Enterprise
          </span>
        </v-col>
        <v-col cols="6">
          <a href="https://www.facebook.com/EastGoldTours/" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="30"
                  color="white"
                  class="mr-3"
                  v-on="on"
                  v-bind="attrs"
                  >mdi-facebook</v-icon
                >
              </template>
              <span>facebook</span>
            </v-tooltip>
          </a>
          <a href="https://wa.me/+254720687790" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="30"
                  color="white"
                  class="mr-3"
                  v-on="on"
                  v-bind="attrs"
                  >mdi-whatsapp</v-icon
                >
              </template>
              <span>whatsapp</span>
            </v-tooltip>
          </a>
          <a href="tel:+254720687790">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="30"
                  color="white"
                  class="mr-3"
                  v-on="on"
                  v-bind="attrs"
                  >mdi-phone</v-icon
                >
              </template>
              <span>phone</span>
            </v-tooltip>
          </a>
          <a href="mailto:your.email@example.com" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="30" color="white" v-on="on" v-bind="attrs"
                  >mdi-at</v-icon
                >
              </template>
              <span>email</span>
            </v-tooltip>
            eastgoldtours@gmail.com
          </a>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                size="30"
                color="white"
                class="ml-3"
                v-on="on"
                v-bind="attrs"
                >mdi-map-marker-radius</v-icon
              >
            </template>
            <span>location</span>
          </v-tooltip>
          <a href="https://www.instagram.com/EastGoldTours/" target="_blank">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="30"
                  color="white"
                  class="ml-3"
                  v-on="on"
                  v-bind="attrs"
                  >mdi-instagram</v-icon
                >
              </template>
              <span>instagram</span>
            </v-tooltip>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { appName } from "../../environment/index";

export default {
  name: "Footer",
  props: {
    opacity: {
      type: String,
      default: "0",
    },
  },

  computed: {
    color() {
      return `rgba(255, 255, 255, ${this.opacity})`;
    },

    appName() {
      return appName;
    },
  },
};
</script>

<style scoped></style>
